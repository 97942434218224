import React from 'react'
import CaseDetails from '../templates/case-detail'
import financial from "../images/solutions/financial.jpeg"
import smartCity from "../images/solutions/smart-city.jpeg"
import solutionpage from "../images/solutions/solution-page.png"
import listImg1 from "../images/case/caseimg1.jpeg"
import listImg2 from "../images/case/caseimg2.jpeg"
import listImg3 from "../images/case/caseimg3.jpeg"
import listImg4 from "../images/case/caseimg1.jpeg"
export default function CaseDetail() {
    let  idx = typeof window !=="undefined"? window.location.search.slice( window.location.search.length - 1):""
    console.log(idx);
    const caseDetailData = [
      {
        id:0,
        img:listImg1,
        title:"Public Health Agency of Canada",
        text:"PHAC leveraged the Sightline Datatrust and AI platform technology to monitor mental health and behavioural patterns around suicide ideation and ideation risk. It used social media data sources to monitor & predict (with AI) aggregate level mental health risk across Canada. The outcome was a privacy compliant real time monitoring system for suicide ideation risk. PHAC is now able to utilize new data sources such as social media."
      },
      {
        id:1,
        img:listImg2,
        title:"Real Time Medical",
        text:"Leveraging private and public datasets, with the Sightline AI & Datatrust platform, a recommendation engine was deployed for radiologists that ensures all patient data is protected. This system helped them to better understand the care needed for their patients, improve their knowledge around best practices and optimize scheduling of caseload."
      },
      {
        id:2,
        img:listImg3,
        title:"Safe at Work",
        text:"Safe at Work is an IOT based application designed to manage the spread of COVID-19 in the workplace. It creates a unique digital twin representation of individual behaviour in a privacy preserving manner. This digital twin is used to create exposure models for individuals based on their behaviour and contact. To collaborate with critical personal data in a privacy preserving, compliant and secure manner."
      },
      {
        id:3,
        img:listImg4,
        title:"City of Toronto",
        text:"The City of Toronto contracted Sightline Innovation to explore the use of datatrust technology for various Connected Community use cases. The final selected use case for the datatrust project was “Integrated Community Services”. Sightline Innovation, in partnership with Cisco Systems Canada, utilized the Sightline datatrust platform to explore how the current challenges around community benefits and service delivery could be transformed to benefit both the citizens as well as internal city stakeholders."
      }
    ]
    // const caseDetailData = [
    //     {
    //         id:0,
    //         img:listImg1,
    //         title:"Data security and protection for Healthcare",
    //         textTitle1:"Trusted data collaboration with multiple stakeholders",
    //         text1:"The healthcare industry requires stakeholders across numerous institutions to collaborate on data in a secure manner. DTaaS provides secure collaboration for this sensitive data along with analytics tools, in a user-friendly environment.",
    //         textTitle2:"Data-driven decisions in healthcare settings",
    //         text2:"Data-driven decisions are essential in healthcare and help to improve processes and human output, and to facilitate ongoing learning within healthcare systems. DTaaS empowers healthcare organizations to create better outcomes.",
    //         textTitle3:"Anonymization of data",
    //         text3:"Healthcare organizations constantly deal with personally patient data. DTaaS facilitates compliance with global privacy legislation, and creates an audit trail of data usage and enforces data usage rights via smart contract, enabling precision healthcare."    
    //     },
    //     {
    //       id:1,
    //       img:listImg2,
    //       title:"Solutions for Smart Cities",
    //       textTitle1:"Improve municipal and state services",
    //       text1:"DTaaS can be utilized to improve sustainability and access to civil services, streamline tenant relations in city housing, integrate community services across public sector divisions.",
    //       textTitle2:"Protect data of citizens",
    //       text2:"How data is collected, processed, applied, governed and protected are fundamental in smart cities. DTaaS creates solutions that power smart cities without compromising the privacy and confidence of citizens.",
    //       textTitle3:"Gain valuable insights from data",
    //       text3:"DTaaS enables data collaboration across public and private sector organizations to allow for real-time insights into everyday actions and longer term strategic decisions."
    //     },
    //     {
    //       id:2,
    //       img:listImg3,
    //       title:"Data security for Financial Services",
    //       textTitle1:"Improved risk mitigation and fraud prevention",
    //       text1:"Financial services institutions face increasing regulatory compliance requirements and also require adaptable risk mitigation and fraud prevention measures. DTaaS regulatory compliance and enables institutions to rapidly respond to these needs.",
    //       textTitle2:"Data-driven decisions in financial services",
    //       text2:"Prompt, secure and timely access to data is essential for financial services institutions. DTaaS empowers these institutions to make more-informed decisions through a single source of truth datatrust.",
    //       textTitle3:"Data monetization and securitization",
    //       text3:"DCreate new revenue streams from data and derivative data through licensing arrangements with third parties. Use data as a means to shore up balance sheets and unlock shareholder value."
    //     },
    //     {
    //       id:3,
    //       img:listImg4,
    //       title:"Data security for Financial Services",
    //       textTitle1:"Improved risk mitigation and fraud prevention",
    //       text1:"Financial services institutions face increasing regulatory compliance requirements and also require adaptable risk mitigation and fraud prevention measures. DTaaS regulatory compliance and enables institutions to rapidly respond to these needs.",
    //       textTitle2:"Data-driven decisions in financial services",
    //       text2:"Prompt, secure and timely access to data is essential for financial services institutions. DTaaS empowers these institutions to make more-informed decisions through a single source of truth datatrust.",
    //       textTitle3:"Data monetization and securitization",
    //       text3:"DCreate new revenue streams from data and derivative data through licensing arrangements with third parties. Use data as a means to shore up balance sheets and unlock shareholder value."
    //     },
        
        
    // ]
  return (
    <CaseDetails data={caseDetailData[idx]} />
  )
}
