import React, { useState, useEffect } from 'react'
import Header from '../components/header'
import Footer from '../components/footer'
import "../style/caseDetail.less"
import { navigate } from 'gatsby'
import listImg1 from "../images/case/caseimg1.jpeg"
import listImg2 from "../images/case/caseimg2.jpeg"
import bottomImg from "../images/case/listImg3.png"
import arrow from "../images/case/arrowRight.png"
import Fade from "react-reveal/Fade"
export default function CaseDetail(props) {
    let id = typeof window !== "undefined" ? window.location.search.slice(window.location.search.length - 1) : ""

    const [fixed, setFixed] = useState(false)
    useEffect(() => {
        if (typeof window !== "undefined") {
            window.addEventListener("scroll", hadleScroll)
        }
    }, [])
    const hadleScroll = (e) => {
        let scrollTop = (e.srcElement ? e.srcElement.documentElement.scrollTop : false) || window.pageYOffset || (e.srcElement ? e.srcElement.body.scrollTop : 0);
        // console.log(scrollTop);
        if (scrollTop >= 200 && scrollTop < 2300) {
            setFixed(true)
        } else if (scrollTop < 200) {
            setFixed(false)
        } else if (scrollTop >= 2300) {
            setFixed(false)
        } else if (scrollTop < 2300 && scrollTop >= 200) {
            setFixed(true)
        }
    }
    return (
        <>
            <div className='caseDetail'>
                <Header />
                <div className='caseDetail_content'>
                    <h6>Case-details</h6>
                    <div className='content'>
                        {/* <div className={`text ${fixed?"fixed":"absolute"}`}> */}
                        <div className={`absolute text`}>
                            {/* <h3>Public Health Agency of Canada</h3> */}
                            <Fade distance={"20%"} top duration={1300}  >
                                <h3>{props.data?.title || ""}</h3>
                            </Fade>
                            <div>
                                <span>Client</span>
                                <span>Government of Canada</span>
                            </div>
                            <div>
                                <span></span>
                                <span style={{ textDecoration: "underline" }}>sightlineinnovation.com</span>
                            </div>
                            <hr />
                            <div>
                                <span>product</span>
                                <span style={{ textDecoration: "underline" }}>Securely collaborate</span>
                            </div>
                        </div>

                        <div className='list'>
                            <div><img src={props.data?.img || listImg1} alt="" /></div>
                            <Fade distance={"20%"} top duration={1300}  >
                                <h3>{props.data?.title || ''}</h3>
                            </Fade>
                            <p>{props.data?.text || ''}</p>

                        </div>
                    </div>
                    <ul className='back_to_list'>
                        <li onClick={() => navigate(`/caseDetail?id=${id == 0 ? 3 : Number(id) - 1}`)}><img src={arrow} style={{ transform: "rotate(180deg)" }} alt="" /></li>
                        <li onClick={() => navigate("/case")}>Back to list</li>
                        <li onClick={() => navigate(`/caseDetail?id=${id == 3 ? 0 : Number(id) + 1}`)}><img src={arrow} alt="" /></li>
                    </ul>
                    <div className='bottom_part'>
                        <div>
                            <div onClick={() => navigate("/caseDetail?id=" + 0)} className='img'>
                                <img src={listImg1} alt="" />
                            </div>
                            <div className='text'>
                                <h6>medical</h6>
                                <Fade distance={"20%"} top duration={1300}  >
                                    <h3>Public Health Agency of Canada</h3>
                                </Fade>
                            </div>
                        </div>
                        <div>
                            <div onClick={() => navigate("/caseDetail?id=" + 1)} className='img'>
                                <img src={listImg2} alt="" />
                            </div>
                            <div className='text'>
                                <h6>medical</h6>
                                <Fade distance={"20%"} top duration={1300}  >
                                    <h3>Real Time Medical</h3>
                                </Fade>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
